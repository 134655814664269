<template>
  <div id="app">
    <div v-bind:class="cookiesAllow ? 'main' : 'main_'">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
    <cookie-law
      @accept="accept"
      storageType="cookies"
      storageName="seyvom--cookie.accepted"
      theme="blood-orange--rounded"
    ></cookie-law>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import Vue from "vue";
import VueGtag from "vue-gtag";
import store from "./store";

export default {
  name: "App",
  components: {
    CookieLaw,
  },
  data: function() {
    return {
      cookiesAllow: false,
    };
  },
  methods: {
    accept() {
      Vue.use(VueGtag, {
        config: { id: "G-NBGMRP2YDQ" },
      });
      this.$gtag.event("Seyvom.com", { method: "Google" });
      this.cookiesAllow = true;
    },
  },
  mounted() {
    this.cookiesAllow = store.getters.iscookiesAllowd;
  },
};
</script>

<style>
#app {
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main_ {
  opacity: 0.4;
}
.main {
  opacity: 1;
}
.Cookie--blood-orange--rounded {
  font-family: Poppins-Bold, Poppins;
  color: #fff;
  padding: 1.25em;
}
.Cookie--blood-orange--rounded .Cookie__button {
  color: #212121 !important;
  font-family: Poppins-Bold, Poppins !important;
  font-weight: 700 !important;
  background-color: #ffce00 !important;
}
.Cookie--blood-orange--rounded .Cookie__button:hover {
  background: #e9bf17 !important;
}
</style>
