import axios from "axios";

const baseDomain = "https://api.seyvom.com";
const baseURL = `${baseDomain}`;

const API = axios.create({
  baseURL: baseURL
});

export { API };
