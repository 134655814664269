import Vue from "vue";
import Vuex from "vuex";
import VueCookies from 'vue-cookies'
//Repository ENDPONT
import service from "../repository";
// vue-cookies
Vue.use(Vuex);
Vue.use(VueCookies)

export default new Vuex.Store({
  state: {
    letsTalkBtn: false,
    cookiesAllow: Vue.$cookies.get("seyvom--cookie.accepted")
  },
  getters: {
    iscookiesAllowd: state => 
    {
      return state.cookiesAllow;
    }
  },
  mutations: {
  },
  actions: {
    SubmitLetsTalk(context, data) {
      return service.PostSubmitLetsTalk(data);
    }
  },
  modules: {}
});
