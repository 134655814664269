import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function lazyLoad(view){
  return() => import(`@/view/${view}.vue`)
}

const routes = [
  /*{
    path: "/",
    name: "Comingsoon",
    component: lazyLoad('ComingSoon'),
    meta: {
      title: 'Seyvom Creative - Digital Solutions',
    }
  },*/
  {
    path: "/",
    name: "Home",
    component: lazyLoad('Home'),
    meta: {
      title: 'Seyvom Creative - Home',
    }
  },
  {
    path: "/lets-talk",
    name: "LetsTalk",
    component: lazyLoad('LetsTalk'),
    meta: {
      title: "Seyvom Creative - Let's Talk",
    }
  },
  {
    path: "/biography/vipu",
    name: "VipuBiography",
    component: lazyLoad('VipuBiography'),
    alias: '/biography/vibushanan',
    meta: {
      title: "Seyvom Creative - Vipu",
    }
  },
  {
    path: "/biography/braveen",
    name: "BraveenBiography",
    component: lazyLoad('BraveenBiography'),
    meta: {
      title: "Seyvom Creative - Braveen",
    }
  },
  {
    path: "*", //Page not found
    name: "Page404",
    component: lazyLoad('Page404'),
    meta: {
      title: "Seyvom Creative - Page 404",
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Seyvom Creative - Digital Solutions';
  next();
})
export default router;
