import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// Jquery
import jquery from "jquery";
// bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// font-awesome
import "font-awesome/css/font-awesome.min.css";
// parallax-js
import Parallax from "parallax-js";
// AOS
import AOS from "aos";
import "aos/dist/aos.css";
new AOS.init();

// import globally jquery and parallax
Object.defineProperty(Vue.prototype, '$', { value: jquery });
Object.defineProperty(Vue.prototype, '$parallax', { value: Parallax });

// custom CSS
require("@/assets/style/index.css");

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");