import { API } from "./service";
const resource = "/api/lets-talk"; // API route

export default {
  async PostSubmitLetsTalk(data) {
    var formData = new FormData();
    formData.append('fullname', data.fullname);
    formData.append('email', data.email);
    formData.append('message', data.message);
    formData.append('attachment', data.attachment);
    formData.append('g-recaptcha-response', data.grecaptcha_response);
    return new Promise((resolve, reject) => {
      API
        .post(`${resource}`, formData)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
